import Cookies from "js-cookie";
import React, { useEffect, useContext } from "react";
import SubscriptionBox from "../Common/SubscriptionBox";
import { useDispatch, useSelector } from "react-redux";
import { GetAllPlans } from "../../redux/features/AuthService";
import { AuthContext } from "../../contexts/AuthContexts";
import _ from "lodash";

const Subscription = () => {
  const dispatch = useDispatch();
  const { authStatus } = useContext(AuthContext);
  const planDetails = [
    "Unlimited Trusts",
    "Customer Support",
    "Unlimited Access",
    "Advanced Security"
  ]

  document.title = "National Trust Registry | Verify";
  const { getAllPlansList } = useSelector((state) => state.auths);

  useEffect(() => {
    dispatch(GetAllPlans());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    Cookies.remove("ntr-token");
    Cookies.remove("ntr-verify-token");
    window.location.reload();
  };

  return (
    <>
      <div style={{ height: '100vh' }}>
        <div className="container h-100">
          {((authStatus == 1) && (window.location.pathname == '/purchase_subscription')) && <button className="SiteBtn subscriptionLogout" onClick={handleLogout}>Logout</button>}
          <div className="row py-5 justify-content-center px-md-4">
            <div className="col-md-9">
              <div className="subscriptionMainTitle position-relative">
                <h1>Find the plan that suits your needs.</h1>
              </div>
              <div className="subscriptionMainTitle2">
                <p className="h3 fw-normal text-muted">Simple and flexible per-user pricing.</p>
                <h2 className="mt-5 text-left h2 mb-4 ps-md-5">Choose a Plan to Access:</h2>
                <div className="row text-left h3 fw-normal ps-md-5">
                  {_?.map(planDetails, (item, index) => (
                    <div className="col-sm-5 mb-3" key={index}>
                      <img
                        src="/assets/images/check.svg"
                        alt="check"
                        width="25px"
                        className="img-fluid me-3"
                      />
                      {item}
                    </div>
                  ))}
                </div>
              </div>

              <SubscriptionBox
                subscriptionDataList={getAllPlansList} />

            </div>
          </div>
        </div>
      </div>



    </>
  );
};

export default Subscription;
