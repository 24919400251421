import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../Common/Loader";
import { PaymentPurchaseService, ApplyCoupon } from "../../redux/features/AuthService";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const cardStyle = {
  base: {
    color: "#32325d",
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4"
    }
  },
  invalid: {
    iconColor: '#fa755a',
    color: '#fa755a',
  },
  complete: {
    iconColor: '#00b300',
    color: '#00b300',
  },
};

const StripeCheckoutForm = ({selectedProduct, handleStripeCheckoutFormPopup, checkoutFormType}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [couponCode, setcouponCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [couponDetail, setCouponDetail] = useState(null);
  const [couponError, setCouponError] = useState(null);
  const [productAmount, setProductAmount] = useState(selectedProduct?.price);

  const ApplyStripeCoupon = async (e, planId) => {
    e.preventDefault();
    setLoading(true);

    const res = await dispatch(ApplyCoupon({ couponCode, planId }));
    if(res.type === 'auths/ApplyCoupon/fulfilled') {
      const { coupon, amount, error } = res.payload;

      if(error) { setCouponError(error) }
      if(!isNaN(parseInt(amount)) && coupon) {
        setProductAmount(parseInt(amount));
        setCouponDetail(coupon);
      }

      setLoading(false);
    }
  }

  const RemoveStripeCoupon = async (e) => {
    e.preventDefault();

    setCouponError('');
    setCouponDetail(null);
    setProductAmount(selectedProduct?.price);
  }

  const handleSubmit = async (e, planId) => {
    e.preventDefault();
    if(couponError) { setcouponCode('') }
    setCouponError('');
    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("Card details are not complete.");
      setLoading(false);
      return;
    }

    const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if(paymentMethodError) {
      setError("Something went wrong when trying to create the payment method. Kindly resubmit the form");
      setLoading(false);
      return;
    } else {
      const res = await dispatch(PaymentPurchaseService({ planId, paymentMethodId: paymentMethod?.id, couponCode, productAmount, checkoutFormType }));

      if(res.type === 'auths/PaymentPurchaseService/fulfilled') {
        let data = res.payload?.data;
        let user = data.userInfo;
        let clientSecret = data.clientSecret;

        if(clientSecret) {
          const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: { name: `${user.firstName} ${user.lastName}` },
            },
          });

          setLoading(false);
          handleStripeCheckoutFormPopup(false);
          if (paymentIntent) {
            window.location.replace(data.success_url);
          } else if (error) {
            window.location.replace(data.cancel_url);
          }
        } else {
          let subscription = data.subscription;

          setLoading(false);
          handleStripeCheckoutFormPopup(false);

          if (subscription?.error) {
            window.location.replace(data.cancel_url);
          } else {
            window.location.replace(data.success_url);
          }
        }
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      {selectedProduct && (
        <div className="checkout-form">
          <div className="checkout-header">
            <h2>{selectedProduct?.title} (${selectedProduct?.price})</h2>
            <strong onClick={() => {handleStripeCheckoutFormPopup(false)}}>X</strong>
          </div>
          {
          (couponDetail) ? <>
          <div className="coupon-section align-items-end coupon-container">
            <div className="w-100 mr-4">
              <strong className="card-title">{couponDetail?.name}</strong>
              <p className="card-text">discounted amount: ${productAmount}</p>
            </div>
            <button type="button" className="rounded-3 btn btn-danger" onClick={(e) => {RemoveStripeCoupon(e)}}>Remove</button>
          </div>
          </> : <>
          <form className="coupon-section align-items-end" onSubmit={(e) => { ApplyStripeCoupon(e, selectedProduct?._id) }}>
            <div className="w-100 mr-4">
              <label htmlFor="coupon">Apply Coupon</label>
              {couponError && <div className="error">{couponError}</div>}
              <input
                type="text"
                id="coupon"
                value={couponCode}
                onChange={(e) => setcouponCode(e.target.value)}
                placeholder="Enter a coupon code..."
              />
            </div>
            <button className="rounded-3">Apply</button>
          </form>
          </>
          }

          <form onSubmit={(e) => { handleSubmit(e, selectedProduct?._id) }}>
            <div>
              <label htmlFor="cardDetails">Enter Card Details</label>
              <div className="card-section" id="cardDetails">
                <CardElement options={{ style: cardStyle }} />
              </div>
            </div>
            {error && <div className="error">{error}</div>}
            <button className="rounded-3" type="submit" disabled={!stripe || loading}>Pay</button>
          </form>
        </div>
      )}
    </>
  )
};

export default StripeCheckoutForm;
