import React, { useState } from "react";
import _ from "lodash";
import ModalCustom from "../Common/ModalCustom";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_URL);

const SubscriptionBox = ({ subscriptionDataList }) => {
  const [showStripeCheckoutForm, handleStripeCheckoutFormPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  const handleStripeCheckoutForm = (subscriptionData) => {
    handleStripeCheckoutFormPopup(false);
    setSelectedProduct(subscriptionData)
    handleStripeCheckoutFormPopup(true);
  };

  // function numberWithCommas(x) {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  return (
    <>
      <div>
        {_?.map(subscriptionDataList, (subscriptionData, index) => (
          <div key={index} className={`plan ${subscriptionData?.title === 'Emerald' && 'active'} my-4 bg-light rounded-4 d-sm-flex justify-content-between gap-3 align-items-center py-3 px-4`}>
            <h4 className="plan-title text-uppercase fs-5 mb-0 text-sm-center">{subscriptionData?.title}</h4>
            <div className="plan-price d-inline-flex my-2 my-sm-0">
              <small className="pt-1">$</small>
              <div className="amount fw-normal lh-noraml">{Math.floor(subscriptionData?.price)}.</div>
              <small className="pt-1">{subscriptionData?.price.toString().split('.')[1]}</small>
              <span className="align-content-around mt-3 text-muted fw-medium">/{subscriptionData?.subTitle}</span>
            </div>
            <button type="button" className="fw-medium fs-5 py-1 px-2 rounded-3 text-uppercase" onClick={() => handleStripeCheckoutForm(subscriptionData)}>
              {"CHOOSE" + "  " + subscriptionData?.title}
            </button>
          </div>

        ))}
      </div>

      <ModalCustom
        size="xs"
        show={showStripeCheckoutForm}
      >
        <Elements stripe={stripePromise}>
          <StripeCheckoutForm
            checkoutFormType='subscription'
            selectedProduct={selectedProduct}
            handleStripeCheckoutFormPopup={handleStripeCheckoutFormPopup}
          />
        </Elements>
      </ModalCustom>
    </>
  );
};

export default SubscriptionBox;
